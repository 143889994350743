import React from "react";
import PatternBackground from "./PatternBackground";
import Image from "./Image";
import { Link } from "gatsby";

const ArticleThumbnail = ({ title, publishedAt, author, mainImage, slug }) => {
  let date = new Date(publishedAt).toLocaleDateString("no-NB", {
    day: "numeric",
    month: "long",
  });
  return (
    <Link to={`/artikler/${slug.current}`} className="mb-4">
      <PatternBackground>
        <Image
          image={mainImage}
          className="w-full rounded-ryddi-sm"
          aspectRatio={9 / 16}
          alt={title}
        />
      </PatternBackground>
      <div className="px-4 mt-4">
        <h4 className="text-subheading-2">{title}</h4>
        <p className="text-caption mt-2">
          {author.name} • {date}
        </p>
      </div>
    </Link>
  );
};

export default ArticleThumbnail;
