import { graphql } from "gatsby";
import React from "react";
import ArticleThumbnail from "../components/ArticleThumbnail";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

const Artikler = ({
  data: {
    allSanityArticle,
    sanityArticlePage: { title },
  },
}) => {
  return (
    <>
      <Nav />
      <main className="min-h-screen max-w-screen-2xl m-auto px-page">
        <h1 className="text-heading-4 max-w-prose mb-16 mt-8 md:text-heading-2">
          {title}
        </h1>
        <div className="grid article-grid-md gap-8">
          {allSanityArticle.edges.map(({ node: article }) => (
            <ArticleThumbnail {...article} key={article.slug.current} />
          ))}
        </div>
      </main>
      <CookieBanner />
      <Footer />
    </>
  );
};

export const query = graphql`
  query {
    allSanityArticle(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          title
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 800)
            }
          }
          author {
            name
          }
          publishedAt
          slug {
            current
          }
        }
      }
    }
    sanityArticlePage(_id: { regex: "/articlePage$/" }) {
      title
    }
  }
`;

export default Artikler;
