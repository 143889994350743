import React from "react";
import pattern from "../images/RyddiBackgroundPattern.svg";

const PatternBackground = ({ children, className, ...props }) => {
  return (
    <div
      className={"p-8 relative rounded overflow-hidden " + className}
      {...props}
    >
      <img className="w-full h-full absolute top-0 left-0" src={pattern} />
      {children}
    </div>
  );
};

export default PatternBackground;
